import React from 'react'
import { Link } from 'gatsby'
import Helmet from "react-helmet";

const PP = () => (
	<>

	{/* <div className="black">
	<h1><a href='#'>AI assistant</a> – Privacy Policy</h1>

	<p>Effective date: March 15, 2024</p>

	<p>Alexander Yun ("us", "we", or "our") operates the the "<a href='#'>AI assistant" mobile application</a> (the "Service").</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<h2>Information Collection And Use</h2>
	<p>We don’t use, share or otherwise expose any type of user's data while you are using the "<a href='#'>AI assistant</a>" mobile app (the "Service"). All the data you enter while using the "Service" is kept privately for you sole use without being transferred, stored or shared with any other third parties.</p>
	</div> */}


<div className="black">
	<h1>Now/Here – Privacy Policy</h1>
	<p>Effective date: August 16, 2024</p>
	<p>Barcelona Code School ("us", "we", or "our") operates the "Now/Here" mobile application (the "Service") for iOS and Android platforms.</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<h2>Information Collection And Use</h2>
	<p>We donߴt collect any types of information while you are using the "Now/Here" mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
	<p>The app requires to have access to your current location to be able to determine the current weather. This data is not used any way otherwise, stored or passed anywhere from your device.</p>
	</div>



	<div className="white">
	<h1>Weather OS – Privacy Policy</h1>
	<p>Effective date: September 28, 2022</p>
	<p>Barcelona Code School ("us", "we", or "our") operates the "Weather OS" mobile application (the "Service") for <a href='https://apps.apple.com/us/app/weather-os/id6443399556' target='blank'>iOS</a> and <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.weather_os' target='blank'>Android</a> platforms.</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<h2>Information Collection And Use</h2>
	<p>We donߴt collect any types of information while you are using the "Weather OS" mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
	<p>The app requires to have access to your current location to be able to determine the current weather. This data is not used any way otherwise, stored or passed anywhere from your device.</p>
	</div>

	<div className="black">
	<h1><a href='https://apps.apple.com/us/app/use-it-up-a-recipe-database/id1636287829' target='blank'>Use It Up</a> – Privacy Policy</h1>

	<p>Effective date: July 20, 2022</p>

	<p>Barcelona Code School ("us", "we", or "our") operates the "<a href='https://apps.apple.com/us/app/use-it-up-a-recipe-database/id1636287829' target='blank'>Use It Up" mobile application</a> (the "Service").</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<h2>Information Collection And Use</h2>
	<p>We don’t collect any types of information while you are using the "<a href='https://apps.apple.com/us/app/use-it-up-a-recipe-database/id1636287829' target='blank'>Use It Up</a>" mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transferred, stored or shared with any other devices or resources.</p>
	</div>


	<div className="white">
	<h1><a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target='blank'>I have, I need – recurring shopping list</a> – Privacy Policy</h1>
	<p>Effective date: July 01, 2022</p>
	<p>Barcelona Code School ("us", "we", or "our") operates the "I have, I need – recurring shopping list" mobile application (the "Service") for <a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target='blank'>iOS</a> and <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.ihaveineed' target='blank'>Android</a> platforms.</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<h2>Information Collection And Use</h2>
	<p>We donߴt collect any types of information while you are using the "<a href='https://apps.apple.com/us/app/i-have-i-need/id1621110781' target='blank'>I have, I need – recurring shopping list</a>" mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
	</div>

	<div className="black">
	<h1><a href='https://apps.apple.com/es/app/the-gallows/id1613918820?l=en' target='blank'>The Gallows</a> – Privacy Policy</h1>

	<p>Effective date: March 11, 2022</p>

	<p>Barcelona Code School ("us", "we", or "our") operates the "The <a href='https://apps.apple.com/es/app/the-gallows/id1613918820?l=en' target='blank'>Gallows" mobile application</a> (the "Service").</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<h2>Information Collection And Use</h2>
	<p>We don’t collect any types of information while you are using the "<a href='https://apps.apple.com/es/app/the-gallows/id1613918820?l=en' target='blank'>The Gallows</a>" mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transferred, stored or shared with any other devices or resources.</p>
	</div>



	<div className="white">
	<h1><a href='https://apps.apple.com/us/app/neumorphism-weather/id1580923671' target='blank'>Neumorphism weather</a> – Privacy Policy</h1>

	<p>Effective date: August 13, 2021</p>

	<p>Barcelona Code School ("us", "we", or "our") operates the "<a href='https://apps.apple.com/us/app/neumorphism-weather/id1580923671' target='blank'>Neumorphism weather</a>" mobile application (the "Service").</p>

	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

	<h2>Information Collection And Use</h2>

	<p>We don't collect any types of information while you are using the "<a href='https://apps.apple.com/us/app/neumorphism-weather/id1580923671' target='blank'>Neumorphism weather</a>" mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>

	<p>"<a href='https://apps.apple.com/us/app/neumorphism-weather/id1580923671' target='blank'>Neumorphism weather</a>" mobile app (the "Service") is using your current geolocation to display the current local weather by using an external API with only the coordinates being sent there without any kind of user's personal details or data.</p>
	</div>



	
	{/*<div className="black">
	<h1>iReport: incidents near you – Privacy Policy</h1>

	<p>Effective date: August 10, 2021</p>

	<p>Barcelona Code School ("us", "we", or "our") operates the "iReport: incidents near you" mobile application (the "Service").</p>

	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

	<h2>Information Collection And Use</h2>

	<p>We don't collect any types of information while you are using the "iReport: incidents near you" mobile app (the "Service"). All the data you enter while using the "Service" is used solely to be displayed within the app for other authenticated users as "user generated content".</p>

	<p>"iReport: incidents near you" mobile app (the "Service") is using your current geolocation to display the posts about incidents around your current location. "iReport: incidents near you" is asking for access to user's photos library in user's mobile device to make it possible for the users to publish new posts about incidents happening around them.</p>
</div>*/}


<div className="black">
<h1><a href='https://apps.apple.com/us/app/anime-calculator/id1567378874' target='blank'>Anime Calculator</a> – Privacy Policy</h1>

<p>Effective date: May 12, 2021</p>

<p>Barcelona Code School ("us", "we", or "our") operates the <a href='https://apps.apple.com/us/app/anime-calculator/id1567378874' target='blank'>Anime Calculator</a> mobile application (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<h2>Information Collection And Use</h2>

<p>We don't collect any types of information while you are using the <a href='https://apps.apple.com/us/app/anime-calculator/id1567378874' target='blank'>Anime Calculator</a> mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
</div>



<div className="white">
<h1><a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='blank'>Guess a word or die</a> – Privacy Policy</h1>
<p>Effective date: October 01, 2020</p>
<p>Barcelona Code School ("us", "we", or "our") operates the <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='blank'>Guess a word or die</a> mobile application (the "Service").</p>
<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
<h2>Information Collection And Use</h2>
<p>We don't collect any types of information while you are using the <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='blank'>Guess a word or die</a> mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
</div>


<div className="black">
<h1><a href='https://apps.apple.com/us/app/rechargenow/id1527735701' target='blank'>RechargeNow</a> – Privacy Policy</h1>
<p>Effective date: August 15, 2020</p>
<p>Barcelona Code School ("us", "we", or "our") operates the <a href='https://apps.apple.com/us/app/rechargenow/id1527735701' target='blank'>RechargeNow</a> mobile application (the "Service").</p>
<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
<h2>Information Collection And Use</h2>
<p>We don't collect any types of information while you are using the <a href='https://apps.apple.com/us/app/rechargenow/id1527735701' target='blank'>RechargeNow</a> mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
<p><a href='https://apps.apple.com/us/app/rechargenow/id1527735701' target='blank'>RechargeNow</a> mobile app (the "Service") is using your current geolocation to display available charging points by using an external API with only the coordinates being sent there without any kind of user's personal details or data.</p>
</div>


<div className="white">
<h1><a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Weather'n'jokes</a> – Privacy Policy</h1>

<p>Effective date: July 21, 2020</p>

<p>Barcelona Code School ("us", "we", or "our") operates the <a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Weather'n'jokes</a> mobile application (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<h2>Information Collection And Use</h2>

<p>We don't collect any types of information while you are using the <a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Weather'n'jokes</a> mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>

<p><a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Weather'n'jokes</a> mobile app (the "Service") is using your current geolocation to display the current local weather by using an external API with only the coordinates being sent there without any kind of user's personal details or data.</p>
</div>


<div className="black">
<h1><a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Turtle Weather</a> – Privacy Policy</h1>

<p>Effective date: May 21, 2020</p>

<p>Barcelona Code School ("us", "we", or "our") operates the <a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Turtle Weather</a> mobile application (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<h2>Information Collection And Use</h2>

<p>We don't collect any types of information while you are using the <a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Turtle Weather</a> mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>

<p><a href='https://apps.apple.com/us/app/weathernjokes/id1521831493' target='blank'>Turtle Weather</a> mobile app (the "Service") is using your current geolocation to display the current local weather by using an external API with only the coordinates being sent there without any kind of user's personal details or data.</p>
</div>


<div className="white">
<h1><a href='https://apps.apple.com/us/app/nebulosity/id1555467929' target='blank'>Nebulosity</a> – Privacy Policy</h1>

<p>Effective date: February 25, 2021</p>

<p>Barcelona Code School ("us", "we", or "our") operates the <a href='https://apps.apple.com/us/app/nebulosity/id1555467929' target='blank'>Nebulosity</a> mobile application (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<h2>Information Collection And Use</h2>

<p>We don't collect any types of information while you are using the <a href='https://apps.apple.com/us/app/nebulosity/id1555467929' target='blank'>Nebulosity</a> mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>

<p><a href='https://apps.apple.com/us/app/nebulosity/id1555467929' target='blank'>Nebulosity</a> mobile app (the "Service") is using your current geolocation to display the current local weather by using an external API with only the coordinates being sent there without any kind of user's personal details or data.</p>
</div>




<div className="black" id='wapp'>
<h1>Wapp – Privacy Policy</h1>

<p>Effective date: May 19, 2021</p>

<p>Stefano Demontis ("us", "we", or "our") operates the Wapp mobile application (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<h2>Information Collection And Use</h2>

<p>We don't collect any types of information while you are using the Wapp mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>

<p>Wapp mobile app (the "Service") is using your current geolocation to display the current local weather by using an external API with only the coordinates being sent there without any kind of user's personal details or data.</p>
</div>




<div className="white">
<h1>Budgetizer – Privacy Policy</h1>

<p>Effective date: July 23, 2018</p>

<p>Barcelona Code School ("us", "we", or "our") operates the Budgetizer mobile application (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<h2>Information Collection And Use</h2>

<p>We don't collect any types of information while you are using the Budgetizer mobile app (the "Service"). All the data you enter while using the "Service" is kept in your phone without being transfered, stored or shared with any other devices or resources.</p>
</div>


<div className="black" id='cookies'>
<h1>Cookies</h1>


<p>A cookie is a string of information that a website stores on a visitor’s computer and that the visitor’s browser provides to the website each time the visitor returns. We use cookies to help us identify visitors, their usage of the Site, and their website access preferences to improve their experience, to understand how our services are being used.</p>

<p>To the extent that the cookies we employ are non-essential for the functioning of our Site, we will request your consent for their usage when you first visit our Site. You do not have to accept these cookies.</p>

<p>Please note that third parties (including for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies.</p>
</div>

<div className='white'>
<h2>Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us by <a href="mailto:office@barcelonacodeschool.com">sending the email</a> or visiting <a href="/contacts/">contact page</a>.</p>
</div>
</>
)

export default PP
